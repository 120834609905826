import axios from "@/plugins/axios";

export const getMain = async () => {
  const response = await axios.get(`/main`);
  return response.data;
};

export const getAboutUs = async () => {
  const response = await axios.get(`/about_us`);
  return response.data;
};

export const getCity = async (city) => {
  const response = await axios.get(`/city/${city}`);
  return response.data;
};

export const getItinerary = async (city, id) => {
  const response = await axios.get(`/city/${city}/${id}`);
  return response.data;
};
