import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import getAwesomeSwiper from "@/plugins/vue-awesome-swiper";
import "@/assets/styles/style.scss";
import "aos/dist/aos.css";
import AOS from "aos";
Vue.component("default-layout", DefaultLayout);

Vue.config.productionTip = false
Vue.use(getAwesomeSwiper);
AOS.init({
  once: true,
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
