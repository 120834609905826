<template>
  <div>
    <app-header />
    <div class="default-wrapper" style="min-height: 50vh">
      <slot />
    </div>
    <app-footer />
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
  },
};
</script>
