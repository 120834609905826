<template>
  <div v-if="main" class="home">
    <page-intro :intro="main.main_info"/>
    <about-project :blocks="main.blocks" :collage="main.collage"/>
    <project-routes :cities="main.city_slider"/>
    <route-authors :authors="main.route_authors"/>
    <people-reviews :reviews="main.comments_slider"/>
  </div>
  <!-- <div v-else class="loader">
    <img class="loader__gif" src="@/assets/img/loading.gif" alt="">
  </div> -->
</template>

<script>
import { getMain } from "@/api";

export default {
  name: "HomeView",
  data() {
    return {
      main: null
    }
  },
  components: {
    PageIntro: () => import("@/components/blocks/PageIntro.vue"),
    AboutProject: () => import("@/components/blocks/AboutProject.vue"),
    ProjectRoutes: () => import("@/components/blocks/ProjectRoutes.vue"),
    RouteAuthors: () => import("@/components/blocks/RouteAuthors.vue"),
    PeopleReviews: () => import("@/components/blocks/PeopleReviews.vue"),
  },
  mounted() {
    getMain().then((res) => {
      this.main = res
      console.log('main',this.main)
    });
  },
};
</script>
