import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: '/city',
    name: 'city',
    component: () => import("../views/CityPage.vue"),
  },
  {
    path: '/itinerary',
    name: 'itinerary',
    component: () => import("../views/ItineraryPage.vue"),
  },

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
